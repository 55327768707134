import React from 'react'
import { useState } from 'react'
import axios from "axios";

const ContactForm = ({brands, categories}) => {

  const [registerContactInput, setContact] = useState({
    name: '',     
    phone: '',     
    email: '',
    company: '',
    address: '',
    country: '',
    brand: '',
    category: '',
    message: '',
    error_list:[],
    success:'',
  });
  
  const handleInput = (e) => {
    e.persist();
    setContact({...registerContactInput, [e.target.name]: e.target.value});
  }

  const contactSubmit = (e) => {
    e.preventDefault();

    const data = {        
        name: registerContactInput.name,     
        phone: registerContactInput.phone,     
        email: registerContactInput.email,
        company: registerContactInput.company,
        address: registerContactInput.address,
        country: registerContactInput.country,
        brand: registerContactInput.brand,
        category: registerContactInput.category,
        message: registerContactInput.message,        
    }

    axios.post(`/api/submitContact`, data).then(res => {
        if(res.data.status == 200){              
          setContact({...registerContactInput, name: '', phone: '', email: '', company: '', address: '', country: '', brand: '', category: '', message: '', error_list:[], success: res.data.message});          
        }else{
          setContact({...registerContactInput, error_list: res.data.validation_errors});
        }  
    });  
  }

  return (
    <section className="psm-section contact-form">
      <div className="container">
        <h2>Get in touch</h2>
        <p>One goal, One passion, One Stop Shop for the Wholesale distribution of Branded Health & Beauty Products.</p>        
        <div className="contact-form-wrapper">
          <form onSubmit={contactSubmit}>
            <div className="statusMessage">{registerContactInput.success}</div>
            <ul>
              <li>
                <label htmlFor="name">Name<em>*</em></label>
                <input type="text" name="name" id="name" onChange={handleInput} value={registerContactInput.name} />
                <div className='error-msg'>{registerContactInput.error_list.name}</div>
              </li>

              <li>
                <label htmlFor="phone">Phone<em>*</em></label>
                <input type="text" name="phone" id="phone" onChange={handleInput} value={registerContactInput.phone} />
                <div className='error-msg'>{registerContactInput.error_list.phone}</div>
              </li>

              <li>
                <label htmlFor="email">Email<em>*</em></label>
                <input type="text" name="email" id="email" onChange={handleInput} value={registerContactInput.email} />
                <div className='error-msg'>{registerContactInput.error_list.email}</div>
              </li>

              <li>
                <label htmlFor="company">Company Name<em>*</em></label>
                <input type="text" name="company" id="company" onChange={handleInput} value={registerContactInput.company} />
                <div className='error-msg'>{registerContactInput.error_list.company}</div>
              </li>

              <li>
                <label htmlFor="address">Address<em>*</em></label>
                <input type="text" name="address" id="address" onChange={handleInput} value={registerContactInput.address} />
                <div className='error-msg'>{registerContactInput.error_list.address}</div>
              </li>

              <li>
                <label htmlFor="country">Country<em>*</em></label>
                <input type="text" name="country" id="country" onChange={handleInput} value={registerContactInput.country} />
                <div className='error-msg'>{registerContactInput.error_list.country}</div>
              </li>

              <li>
                <label htmlFor="brand">Interested Brand<em>*</em></label>
                {/* <input type="text" name="brand" id="brand" onChange={handleInput} value={registerContactInput.brand} /> */}
                <select name="brand" id="brand" onChange={handleInput} value={registerContactInput.brand}>
                  <option value="">Interested Brands</option>
                  {brands.map(brand => (
                    <option value={brand.title} key={brand.id}>{brand.title}</option>
                  ))}
                  <option value="Others">Others</option>
                </select>
                <div className='error-msg'>{registerContactInput.error_list.brand}</div>
              </li>

              <li>
                <label htmlFor="category">Interested Product Category<em>*</em></label>
                {/* <input type="text" name="category" id="category" onChange={handleInput} value={registerContactInput.category} /> */}
                <select name="category" id="category" onChange={handleInput} value={registerContactInput.category}>
                  <option value="">Interested Category</option>
                  {categories.map(category => (
                    <option value={category.title} key={category.id}>{category.title}</option>
                  ))}
                  <option value="Others">Others</option>
                </select>
                <div className='error-msg'>{registerContactInput.error_list.category}</div>
              </li>

              <li className="full">
                <label htmlFor="message">Message<em>*</em></label>
                <textarea name="message" id="message" onChange={handleInput} value={registerContactInput.message}></textarea> 
                <div className='error-msg'>{registerContactInput.error_list.message}</div>
              </li>

              <li>
                <button type="submit">Send Message</button>
              </li>              
            </ul>
          </form>
        </div>
      </div>
    </section>
  )
}

export default ContactForm
