import React from 'react'
import { Outlet } from "react-router-dom";
import Header from './Header'
import Footer from './Footer'

const Layout = ({loading, menu, settings}) => {
  return (
    <>
      {/* {loading && <div>Loading</div>}
      {!loading && (
      <>     */}
        <Header menu={menu} settings={settings}/>          
          <Outlet />          
        <Footer settings={settings}/>
      {/* </>
      )}        */}
    </>
  )
}

export default Layout