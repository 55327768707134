import React from 'react'
import { useParams } from "react-router-dom";
import parse from 'html-react-parser';

const Banners = ({banners, brand_name, brands, product_name, products}) => {
  const location = useParams();
  const brandName = brands.filter(function(e, i){
    return parse(e.title.toString().normalize('NFKD').toLowerCase().trim().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '').replace(/\-\-+/g, '-')  ) === location.brandName;
  });
  const productName = products.filter(function(e, i){
    return parse(e.title.toString().normalize('NFKD').toLowerCase().trim().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '').replace(/\-\-+/g, '-')  ) === location.productName;
  });

  return (    
    <>
      {
        banners.map((page, index) => {
          return (              
              <React.Fragment key={index}>
              {
                page.banners.map((pageBanner, index) => {
                  return (
                  <section key={index} className={`psm-section ${page.slug}-banner`} style={{ 
                    backgroundImage: `url(${pageBanner.banner_image_full})` 
                  }}>  
                    <div className="container">   
                      {brand_name && 
                          <h2>{brandName[0].title}</h2>
                      }         
                      {product_name && 
                          <h2>{productName[0].title}</h2>
                      }     
                      {!brand_name && !product_name &&    
                        <>
                        {parse(pageBanner.content)}
                        </>
                      }
                      {pageBanner.banner_image_2_full ? 
                      <div className="home-banner-img">
                        <div className="home-banner-img-wrapper" style={{ 
                          backgroundImage: `url(${pageBanner.banner_image_2_full})` 
                        }}>
                        </div>
                      </div>
                      : null}
                    </div>
                  </section>
                  );
                })
              }
              </React.Fragment>              
          );
        })
      }
    </>
  )
}

export default Banners