import React from 'react'
import { Link } from "react-router-dom"

const Nav = ({menu, isopen}) => {
  return (    
    <>
      <nav className={isopen ? "visible" : ""}>
        <ul>
            {/* <li><Link to='/'>Home</Link></li> */}
            {/* {menu.map(item => (
              <li key={item.id}>
                <Link to={`/${item.url}`}>{item.title}</Link>
              </li>
            ))} */}

            {menu.map((item, index) => {
              if (item.display_in_menu == 1) {
                return <li key={index}>
                  {item.title != 'LOGIN' && 
                    <Link to={`/${item.url}`}>{item.title}</Link>
                  }
                  {item.title == 'LOGIN' && 
                    <a href={`${item.url}`} target="_blank">{item.title}</a>
                  }
                </li>;
              }              
            })}            
        </ul>
      </nav>
    </>
  )
}

export default Nav