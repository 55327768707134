import React from 'react'
import { useState } from 'react';
import parse from 'html-react-parser'
import { Link } from "react-router-dom"

const BrandsWithCategory = ({brands, categories}) => {  
  
  return (    
    <section className="psm-section brands">
      <div className="container">
        {/* {categories.map((category, catIndex) => (
          <div key={catIndex} className={`brand ${isOpen ? "open" : ""}`} onClick={() => setOpen(!isOpen)}>
            <h4>{parse(category.title)}</h4>            
              {brands.filter((brands) => brands.category_id === category.id ).map((brand, brandIndex) => (
                <div key={brandIndex} className={`brand-item ${!isOpen ? "collapsed" : ""}`}>
                  <img src={`${brand.image_full}`} />
                </div>
              ))}            
          </div>
        ))} */}
        {categories.map((category, catIndex) => (
          <React.Fragment key={catIndex}>
            <Accordion index={catIndex} id={parse(category.title.toString().normalize('NFKD').toLowerCase().trim().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '').replace(/\-\-+/g, '-')  )} title={<h4>{parse(category.title)}</h4>} content={
              brands
                .sort((a, b) => (a.title > b.title) ? 1 : -1)
                .filter((brands) => brands.category_id === category.id ).map((brand, brandIndex) => (
                <React.Fragment key={brandIndex}>
                  <div className="brand-item-block">
                    <Link to={`/products/${parse(brand.title.toString().normalize('NFKD').toLowerCase().trim().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '').replace(/\-\-+/g, '-')  )}`}><img src={`${brand.image_full}`} alt="" /></Link>
                  </div>
                </React.Fragment>
              ))
            }/>
          </React.Fragment>
        ))}    
      </div>
  </section>
  
  )
}

const Accordion = ({index, id, title, content}) => {
  const [isOpen] = useState(false);
  const handleToggle = () => {
    // setOpen(!isOpen);
    let title = document.querySelectorAll(".accordian-title");    
    for (let i = 0; i < title.length; i++){
      title[i].classList.remove('open');
    }
    let content = document.querySelectorAll(".accordian-content");    
    for (let j = 0; j < content.length; j++){
      content[j].classList.remove('expand');
    }
    title[index].classList.add('open');
    content[index].classList.add('expand');
  };
  
  return(
    <div className="brand" id={id}>      
      <div className={`accordian-title ${isOpen ? "open" : ""}`} onClick={handleToggle}>
        {title}
      </div>
      <div className={`accordian-content ${isOpen ? "expand" : ""}`}>
        <div className="brand-item">
          {content}
        </div>
      </div>
    </div>
  )
}  

export default BrandsWithCategory
