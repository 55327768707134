import Nav from './Nav'
import { useState } from 'react';
import { Link } from "react-router-dom"

const Header = ({menu, settings}) => {  
  const [isOpen, setOpen] = useState(false);
  return (
    <header>
      <div className="container">
        <div className="logo">
          {settings[0].site_logo_full ? 
            <div className="logo">
              <Link to='/'>
                <img src={`${settings[0].site_logo_full}`} />              
              </Link>
            </div>
          : null}
          {settings[0].site_logo_text ? 
            <div className="logo">
              <Link to='/'>
                {settings[0].site_logo_text}             
              </Link>
            </div>
          : null}          
        </div>
        <div className={`burger burger-squeeze ${isOpen ? "open" : ""}`} onClick={() => setOpen(!isOpen)}><div className="burger-lines"></div></div>
        {menu ? 
          <Nav menu={menu} isopen={isOpen} />
        : null}
      </div>
    </header>
  )
}

export default Header