import React, {useState, useMemo} from 'react'
import { useParams } from "react-router-dom";
import parse from 'html-react-parser'
import Banners from './Banners'
import { Link } from "react-router-dom"
import Helmet from 'react-helmet';
import Pagination from './Pagination';

let PageSize = 25;

const Products = ({slug, pages, products, brands}) => {

  const [currentPage, setCurrentPage] = useState(1);
  const location = useParams();
  const page = pages.filter(function (e) {
    return e.slug === slug;
  });

  const brandID = brands.filter(function(e, i){
    return parse(e.title.toString().normalize('NFKD').toLowerCase().trim().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '').replace(/\-\-+/g, '-')  ) === location.brandName;
  });

  const filterproductsByBrand = products.filter(function (e) {    
    return e.brand_id === brandID[0].id;
  });
  

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return filterproductsByBrand.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);  

  function makeTitle(slug) {
    var words = slug.split('-');
    for (var i = 0; i < words.length; i++) {
      var word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }
    return words.join(' ');
  }

  return (
    <>
    <Helmet>
        <title>Brand - {makeTitle(location.brandName)}</title>
        <meta name="description" content="PSM VISION LIMITED is the UK's fastest growing health and beauty international wholesaler and distributor. We specialise in wholesale distribution of branded products such as: vitamins, pharma OTC, medical devices, fragrances, toiletries, dental products, cosmetics, baby products and more. We fulfil every transaction with utmost care and integrity to build everlasting relations with our loyal customers. As a result, we take pride in our ample customer base from whom we receive pleasant feedback consistently." />
        <meta name="keywords" content={`${makeTitle(location.brandName)}`}/>
    </Helmet>
    <Banners banners={page} brand_name={location.brandName} brands={brands} products={products} /> 
    <section className="psm-section psm-products">
      <div className="container">
        {currentTableData.map((product, index) => {
          return (
            <React.Fragment key={index}>
              
                <div className="product">
                  <h2><Link to={`/product/${parse(product.title.toString().normalize('NFKD').toLowerCase().trim().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '').replace(/\-\-+/g, '-')  )}`}>{parse(product.title)}</Link></h2><br />
                  <h3>EAN: {parse(product.content)}</h3>
                </div>
              
            </React.Fragment>
          );
        })}
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={filterproductsByBrand.length}
            pageSize={PageSize}
            onPageChange={page => setCurrentPage(page)}
          />        
      </div>
    </section>
    </>
  )
}

export default Products