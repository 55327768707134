import { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props) => {
  
  const location = useLocation();
  useEffect(() => {
    document.body.scrollTo(0, 0);
    if(location.hash){
      const path = window.location.href;
      const catId = path.slice(path.indexOf("#") + 1);
      const catElement = document.getElementById(catId);     
      catElement.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      catElement.getElementsByClassName("accordian-title")[0].click();
    }

    if(document.querySelector('.burger')){
      document.querySelector('.burger').classList.remove('open');
    }
    if(document.querySelector('nav')){
      document.querySelector('nav').classList.remove('visible');
    }

  }, [location]);

  return <>{props.children}</>
}

export default ScrollToTop