import React from 'react'
import Slider from "react-slick"
import parse from 'html-react-parser'
import { Link } from "react-router-dom"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Brands = ({brands}) => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    infinite: true,
  };

  var groupSize = 20;
  var rows = brands.sort((a, b) => (a.title > b.title) ? 1 : -1).map(function(content, idx) {      
      return <div key={idx} className="home-brands-list-block"><Link to={`/products/${parse(content.title.toString().normalize('NFKD').toLowerCase().trim().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '').replace(/\-\-+/g, '-')  )}`}><img src={`${content.image_full}`} /></Link></div>;      
  }).reduce(function(r, element, index) {    
      index % groupSize === 0 && r.push([]);
      r[r.length - 1].push(element);
      return r;
  }, []).map(function(rowContent, idx2) {      
        return <div key={idx2}><div className="home-brands-lists">{rowContent}</div></div>;
  });

    
  return (
    <section className="psm-section home-brands">
      <div className="container">
        <h2>Brands</h2>
        <p>One goal, One passion, One Stop Shop for the Wholesale distribution of Branded Health & Beauty Products.</p>
        
          <Slider {...settings}>
            {/* {brands.map((brand, index) => (
              <div key={index} className="home-brands-list-block">
                <img src={`${brand.image_full}`} />
              </div>
            ))} */}
            {rows}
          </Slider>
        
      </div>
    </section>
  )
}

export default Brands