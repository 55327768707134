import React from "react";
import { Routes, Route} from "react-router-dom";
import { useState, useEffect } from 'react'
import Layout from "./component/Layout";
import Pages from "./component/Pages";
import Products from "./component/Products";
import Product from "./component/Product";
import ScrollToTop from "./component/ScrollToTop";
import axios from "axios";
axios.defaults.baseURL = 'https://psmvision.com/admin';
// axios.defaults.baseURL = 'http://127.0.0.1:8000/';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json'; 

function App() {

  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState([])  
  const [settings, setSettings] = useState([])
  const [pages, setPages] = useState([])
  const [categories, setCategories] = useState([])
  const [brands, setBrands] = useState([]) 
  const [products, setProducts] = useState([])   

  useEffect(() => {
    const fetchSiteData = async () =>{
        setLoading(true)
        try {
        const {data: response} = await axios.get('api/getSiteData')
          setMenu(response.menu)
          setSettings(response.settings)
          setPages(response.pages)          
          setCategories(response.category)
          setBrands(response.brands)
          setProducts(response.products)
        } catch (error) {
          console.error(error.message)
        }
        setLoading(false)
    }    
    fetchSiteData();
  }, []);

  return (
    <>
      {loading && <div className="loader"><div className="loading"><p>loading</p><span></span></div></div>}
      {!loading && (    
      <ScrollToTop>  
      <Routes>        
          <Route path="/" element={<Layout loading={loading} menu={menu} settings={settings} />}>
            <Route index element={<Pages slug='home' pages={pages} categories={categories} brands={brands} products={products} />} />
            <Route path="/" element={<Pages slug='home' pages={pages}/>} />
            {menu.map(item => (
              <Route key={item.id} path={`/${item.url}`} element={<Pages slug={item.url} pages={pages} categories={categories} brands={brands} products={products} />} />
            ))}         
            <Route path="/products/:brandName" element={<Products slug='products' pages={pages} brands={brands} products={products} />} /> 
            <Route path="/product/:productName" element={<Product slug='product' pages={pages} brands={brands} products={products} />} /> 
          </Route>        
      </Routes>
      </ScrollToTop>    
      )}   
    </>
  );
}
export default App;