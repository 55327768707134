import React from 'react'
import parse from 'html-react-parser';

const PageContent = ({page}) => {
  return (    
    page.map((item, index) => (
      <React.Fragment key={index}>
        <section className="psm-section page-section">
          <div className="container">
            {parse(item.content)}
            {/* <section className={`psm-section ${item.slug}`} key={index}>
              <div className='container'>
                {parse(item.content)}
              </div>
            </section>*/}
          </div>
        </section>
      </React.Fragment>
    ))
  )
}

export default PageContent