import React from 'react'
import { useState, useEffect } from 'react'
import parse from 'html-react-parser';
import { Link } from "react-router-dom"

const Categories = ({categories}) => {  
  const [item, setItem] = useState(categories);
  // const menuItems = [...new Set(categories.map((Val) => Val.title))];
  useEffect(() => {
    const getFirstItem = categories.filter(function (e) {
      return e.id === categories[0].id;
    });     
    setItem(getFirstItem);
  }, []);  

  const filterItem = (curcat) => {
    const newItem = categories.filter((newVal) => {
      return newVal.title === curcat;
    });
     setItem(newItem);
  };
  
  const addClass = (event) => {
    let sections = document.querySelectorAll(".home-category-block");    
    for (let i = 0; i < sections.length; i++){
      sections[i].classList.remove('home-category-block-active');
    }    
    if(event.target.classList.contains('icon-hover-img')){      
      event.target.parentNode.parentNode.classList.add("home-category-block-active");
    }
    // if(event.target.classList.contains('home-category-block-icon')){      
    //   event.target.parentNode.classList.add("home-category-block-active");
    // }
  }  
  return (
    <>
        <section className="psm-section home-categories">
          <div className="container">
            {categories.map((category, index) => {
              return (
                <div key={index} className="home-category" onClick={() => filterItem(category.title)}>
                  <div className={"home-category-block" + (0 === index ? ' home-category-block-active' : "")} onClick={(e) => addClass(e)}>
                    <h6>{parse(category.title)}</h6>
                    <div className="home-category-block-icon">
                      <img src={`${category.image_full}`} className="icon-img" alt="" />
                      <img src={`${category.image_hover_full}`} className="icon-hover-img" alt="" />
                    </div>
                  </div>
                </div>
              );
            })}            
            {item.map((category, index) => {
              return ( 
                <h4 key={index}>
                  <Link to={`/brands#${parse(category.title.toString().normalize('NFKD').toLowerCase().trim().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '').replace(/\-\-+/g, '-')  )}`}>{parse(category.title)}</Link>                   
                </h4>
              );
            })}             
          </div>
        </section>

        {/* <section className="psm-section home-categories-details">
          <div className="container">
            {item.map((category, index) => {
              return ( 
                <span key={index}>
                  <div className="home-categories-details-image" style={{ 
                    backgroundImage: `url(${category.image_banner_full})` 
                  }}></div>
                  <div className="home-categories-details-block">
                      {parse(category.content)}
                  </div>                  
                </span>
              );
            })} 
          </div>
        </section> */}

      {/* <ul>
        {categories.map((category, index) => (
          <li key={index}>
            <h4>{category.title}</h4>
            <img width='50px' src={`http://127.0.0.1:8000/images/categories/${category.image}`} />
          </li>
        ))}
      </ul>
      <ul>
        {categories.map((category, index) => (
          <li key={index}>            
            <div key={index} className='category-content'dangerouslySetInnerHTML={{__html:category.content}} />  
            <img width='200px' src={`http://127.0.0.1:8000/images/categories/banners/${category.image_banner}`} />
          </li>
        ))}
      </ul>       */}
    </>
  )
}

export default Categories