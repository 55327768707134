import React from 'react'
import Banners from './Banners'
import PageContent from './PageContent';
import Categories from './Categories';
import Brands from './Brands';
import BrandsWithCategory from './BrandsWithCategory';
import ContactForm from './ContactForm';
import Helmet from 'react-helmet';

const Pages = ({slug, pages, categories, brands, products}) => {
  const page = pages.filter(function (e) {
    return e.slug === slug;
  });

  const capitalizeFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  
  return (
    <>
      <Helmet>
          <title>{capitalizeFirst(slug)}</title>
          <meta name="description" content="PSM VISION LIMITED is the UK's fastest growing health and beauty international wholesaler and distributor. We specialise in wholesale distribution of branded products such as: vitamins, pharma OTC, medical devices, fragrances, toiletries, dental products, cosmetics, baby products and more. We fulfil every transaction with utmost care and integrity to build everlasting relations with our loyal customers. As a result, we take pride in our ample customer base from whom we receive pleasant feedback consistently." />
          <meta name="keywords" content="psm, psmvision, psmvision limited, psm limited, wholesale, retail, import, export, import export, wholesale price, retail price, best price, amazon retail, amazon wholesale, amazon online, health and beauty, health, beauty, skin, skin care, hair, hair care, mother care, baby care, mother and baby care, medical device, health care, fragrances, toiletries, bioderma, clarins, decleor aroma, institut esthederm, lancaster, moroccanoil, neutrogena, olay, shiseido, st ives, vaseline, ccs, e45, avene, cetaphil, okeeffes, fanola, fudge, loreal, olaplex, revlon, schwarzkopf, shea moisture, tigi, wella, milk shake, aveeno, avent, danone, dr bronners, huggies, johnsons baby, lansinoh, nuby, nuk, pampers, sambucol, vital baby, barry m, bourjois, carmex, clinique, eylure, mac, max factor, maybelline, miss sporty, nip fab, nuxe, nyx, opi, orly, rimmel, sally hansen, duo, oh my lash, better you, centrum, clearblue, dentek, durex, haliborange, bausch lomb, himalaya, holland barrett, listerine, natures aid, oral b, solgar, tepe, valupak, first response, health aid, kalms, seven seas, vita biotics, vizulize, optrex, profoot, scholl, nelson, armani, beckham, bruno banani, calvin klein, davidoff, dior, elizabeth arden, estee lauder, givenchy, gucci, hugo boss, james bond, jo malone, jovan, lacoste, mexx, mont blanc, tom ford, vera wang, euthymol, coty laimant, american crew, brylcreem, dove, gillette, nivea, tena, toni guy, aussie care, simple, gorilla, palmers, veet"/>
      </Helmet>
      {page ?
      <>
        {page[0].banners.length > 0 
        ? <Banners banners={page} brands={brands} products={products} /> 
        : 
          <section className={`psm-section page-banner`}>  
            <div className="container">                    
              <h2>{page[0].title}</h2>       
            </div>
          </section>        
        } 

        {page[0].content
        ? <PageContent page={page}/> 
        : null}      
        
        {slug === 'brands'
        ? <BrandsWithCategory brands={brands} categories={categories}/>
        : null}

        {slug === 'contact'
        ? <ContactForm brands={brands} categories={categories} />
        : null}        
        
        {slug === 'home'
        ? <Categories categories={categories}/>
        : null}

        {slug === 'home'
        ? <Brands brands={brands}/>
        : null}      
      </>
      : <h1>No pages</h1> }
    </>
  )
}

export default Pages
