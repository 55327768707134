import React from 'react'
import { useParams } from "react-router-dom";
import parse from 'html-react-parser'
import Banners from './Banners'
import { Link } from "react-router-dom"
import Helmet from 'react-helmet';

const Product = ({slug, pages, products, brands}) => {
  const location = useParams();
  const page = pages.filter(function (e) {
    return e.slug === slug;
  });

  const productID = products.filter(function(e, i){
    return parse(e.title.toString().normalize('NFKD').toLowerCase().trim().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '').replace(/\-\-+/g, '-')  ) === location.productName;
  });

  function makeTitle(slug) {
    var words = slug.split('-');
    for (var i = 0; i < words.length; i++) {
      var word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }
    return words.join(' ');
  }

  return (
    <>
    <Helmet>
        <title>Product - {makeTitle(location.productName)}</title>
        <meta name="keywords" content={`${makeTitle(location.productName)}`}/>
    </Helmet>
    <Banners banners={page} product_name={location.productName} products={products} brands={brands} /> 
    <section className="psm-section psm-products">
      <div className="container">
        {products.map((product, index) => {
          return (
            <React.Fragment key={index}>
              {product.id === productID[0].id &&
                <div className="product-details">
                  <h3>Product EAN is <strong>{product.content}</strong> for wholesale or retail inquiries you can contact us <br />or write us at <a href="mailto:info@psmvision.co.uk" target="_blank">info@psmvision.co.uk</a></h3><br /><br />
                  <p><Link to="/contact" className="button">Contact Us</Link></p>
                </div>
              }
            </React.Fragment>
          );
        })}
      </div>
    </section>
    </>
  )
}

export default Product